import React from "react";
import {
  BrowserRouter as Switch,
  Routes,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga4';

import "./styles/reset.css";
import "./styles/globals.css";
import "bootstrap/dist/css/bootstrap.css";

if (process.env.NODE_ENV === "production") {
  const TRACKING_ID = "G-Q0MPKR9ZRN";
  ReactGA.initialize(TRACKING_ID);
}

const MetaverseMarketing = React.lazy(() => import("./views/MetaverseMarketing"));
const EventMarketing = React.lazy(() => import("./views/EventMarketing"));
const InfluencerMarketing = React.lazy(() => import("./views/InfluencerMarketing"));
const Home = React.lazy(() => import("./views/Home"));
const LinksPage = React.lazy(() => import("./views/Links"));
const TalkToUs = React.lazy(() => import("./views/TalkToUs"));
const UptrendGrowthStories = React.lazy(() => import("./views/UptrendGrowthStories"));
const InvestmentRelationships = React.lazy(() => import("./views/InvestmentRelationships"));
const Expertise = React.lazy(() => import("./views/Expertise"));
const Media = React.lazy(() => import("./views/Media"));
const UpTrendStore = React.lazy(() => import("./views/UpTrendStore"));
const Influencers = React.lazy(() => import("./views/Influencers"));
const PressAndMedia = React.lazy(() => import("./views/PressAndMedia"));
const Blog = React.lazy(() => import("./views/Blog"));
const Blog1 = React.lazy(() => import("./views/Blog1"));
const Blog2 = React.lazy(() => import("./views/Blog2"));
const Blog4 = React.lazy(() => import("./views/Blog4"));
const Blog5 = React.lazy(() => import("./views/Blog5"));
const Blog3 = React.lazy(() => import("./views/Blog3"));
const Blog6 = React.lazy(() => import("./views/Blog6"));
const Blog7 = React.lazy(() => import("./views/Blog7"));
const Events = React.lazy(() => import("./views/Events"));
const Sandbox = React.lazy(() => import("./views/Sandbox"));
const AngelsProgram = React.lazy(() => import("./views/AngelsProgram"));
const UptrendCareer = React.lazy(() => import("./views/UptrendCareer"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const CookiePolicy = React.lazy(() => import("./views/CookiePolicy"));

function App() {
  return (
    <>
      <React.Suspense>
        <Switch>
          <Routes>
            <Route exact path="/links" element={<LinksPage />} />
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/influencermarketing"
              element={<InfluencerMarketing />}
            />
            <Route exact path="/eventmarketing" element={<EventMarketing />} />
            <Route
              exact
              path="/metaversemarketing"
              element={<MetaverseMarketing />}
            />
            <Route exact path="/contact-us" element={<TalkToUs />} />
            <Route
              exact
              path="/uptrendgrowthstories"
              element={<UptrendGrowthStories />}
            />
            <Route
              exact
              path="/fundraising"
              element={<InvestmentRelationships />}
            />
            <Route exact path="/expertise" element={<Expertise />} />
            <Route exact path="/media" element={<Media />} />
            <Route exact path="/store" element={<UpTrendStore />} />
            <Route exact path="/influencers" element={<Influencers />} />
            <Route exact path="/press-and-media" element={<PressAndMedia />} />
            <Route exact path="/journal" element={<Blog />} />
            <Route
              exact
              path="/journal/working-with-leading-youtube-influencers-a-how-to-guide"
              element={<Blog1 />}
            />
            <Route
              exact
              path="/journal/3-proven-strategies-to-building-brand-awareness-in-2022"
              element={<Blog2 />}
            />
            <Route
              exact
              path="/journal/top-5-influencer-marketing-software-solutions-to-boost-brand-growth-in-2022-part1"
              element={<Blog3 />}
            />
            <Route
              exact
              path="/journal/the-art-of-seo-proven-search-engine-strategies"
              element={<Blog4 />}
            />

            <Route
              exact
              path="/journal/top-5-influencer-marketing-software-solutions-to-boost-brand-growth-in-2022-part2"
              element={<Blog5 />}
            />
            <Route
              exact
              path="/journal/top-regions-with-the-best-marketing-performance"
              element={<Blog6 />}
            />
            <Route
              exact
              path="/journal/new-local-region-tips-to-get-started"
              element={<Blog7 />}
            />
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/sandbox" element={<Sandbox />} />
            <Route exact path="/angelsprogram" element={<AngelsProgram />} />
            <Route exact path="/careers" element={<UptrendCareer />} />
            <Route exact path="/cookie" element={<CookiePolicy />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </Switch>
      </React.Suspense>
    </>
  );
}

export default App;

/*

1. CHange chart animations on all pages ...DONE...
2. Change images
3. Do bees and mission animations
4. Put videos in place of images
5. Blog Click Issue

*/
